import requests from "./request";


// 登录
export function login(data) {
  return requests({
    url: '/api/subsystem/bigs/orgLogin',
    method: 'post',
    data
  })
}
// 验证码
export function getCodeInfo(now) {
  return requests({
    url: '/api/subsystem/bigs/randomImage?key='+now,
    method: 'get',
  })
}
// token校验
export function authCheck() {
  return requests({
    url: '/api/subsystem/authbs/authCheck',
    method: 'get',
    headers: {
      // 'X-Access-Token':localStorage.getItem("token")
    },
  })
}





   // 智能设备_监测数据_重点监控
   export function gardenBelow(farmId,greenHouseId,auths) {
    return requests({
      url: '/api/subsystem/bigs/bsSurvDevice?farmId='+farmId+'&greenHouseId='+greenHouseId+'&auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 智能设备_虫情监测
  export function bugSurvList(farmId,auths,pageNo,pageSize) {
    return requests({
      url: '/api/subsystem/bigs/bsBugSurv?farmId='+farmId+'&auths='+auths+'&pageNo='+pageNo+'&pageSize='+pageSize,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 智能设备_一周温度湿度
  export function weekTemp(auths) {
    return requests({
      url: '/api/subsystem/bigs/bsWeekTemp?auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
   // 信息预警列表
   export function alertMessageList(auths) {
    return requests({
      url: '/api/subsystem/bigs/alertMessageList?auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
   // 产业概况_富硒荣耀
   export function cygkLeft(auths) {
    return requests({
      url: '/api/subsystem/bigs/cygkLeft?auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
   // 产业概况_产业资讯
   export function cygkcyzx(auths) {
    return requests({
      url: '/api/subsystem/bigs/cygkcyzx?auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 产业发展_种植面积_作物产量_谷子产业
  export function cyfzLeft(auths) {
    return requests({
      url: '/api/subsystem/bigs/cyfzLeft?auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 产业发展_农事记录
  export function cyfznsjl(auths) {
    return requests({
      url: '/api/subsystem/bigs/cyfznsjl?auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
   // 交易追溯_销售专题_溯源查询_销售分布
   export function jyzsLeft(auths) {
    return requests({
      url: '/api/subsystem/bigs/jyzsLeft?auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 交易追溯_订单动态
  export function jyzsdddt(auths,platform,pageNo,pageSize) {
    return requests({
      url: '/api/subsystem/bigs/jyzsdddt?auths='+auths+'&platform='+platform+'&pageNo='+pageNo+'&pageSize='+pageSize,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }
  // 公共-根据设备编号获取历史数据曲线
  export function deviceSummary(obj) {
    return requests({
      url: '/api/subsystem/bigs/deviceSummary',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
      method: 'post',
      data:obj
    })
  }

  //  产业发展_经营主体列表
  export function jyztList(auths) {
    return requests({
      url: '/api/subsystem/bigs/jyztList?auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }

   //  产经营主体_种植作物
   export function plantCrops(auths) {
    return requests({
      url: '/api/subsystem/bigs/plantCrops?auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }

  //  产经营主体_生态预警天气
  export function envAlertPage(auths) {
    return requests({
      url: '/api/subsystem/bigs/envAlertPage?auths='+auths,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }

  //  产经营主体_生态预警天气现用
  export function orgEnvAlertPage(auths,pageNo,pageSize,areaId) {
    return requests({
      url: '/api/subsystem/bigs/orgEnvAlertPage?auths='+auths+'&pageNo='+pageNo+'&pageSize='+pageSize+'&areaId='+areaId,
      method: 'get',
      headers: {
        // 'X-Access-Token':window.sessionStorage.getItem("token")
      },
    })
  }

    //  产经营主体_生态预警天气
    export function landList(auths,companyId) {
      return requests({
        url: '/api/subsystem/bigs/landList?auths='+auths+'&companyId='+companyId,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }

    //  产经营主体_生态预警天气
    export function landDetail(auths,landId) {
      return requests({
        url: '/api/subsystem/bigs/landDetail?auths='+auths+'&landId='+landId,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }

    //  产经营主体_设备信息
    export function deviceStatus(auths,companyId) {
      return requests({
        url: '/api/subsystem/bigs/deviceStatus?auths='+auths+'&companyId='+companyId,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }

    //  所有快递
    export function expressList(auths) {
      return requests({
        url: '/api/subsystem/bigs/expressList?auths='+auths,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
    //  所有平台
    export function platformList(auths) {
      return requests({
        url: '/api/subsystem/bigs/platformList?auths='+auths,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
  
    //  产经营主体_生态预警天气
    export function entMessagePage(auths,companyId) {
      return requests({
        url: '/api/subsystem/bigs/entMessagePage?auths='+auths+'&companyId='+companyId,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
     //  物流统计
     export function expressStatistic(auths,express,pageNo,pageSize) {
      return requests({
        url: '/api/subsystem/bigs/expressStatistic?auths='+auths+'&express='+express+'&pageNo='+pageNo+'&pageSize='+pageSize,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
    //  预警列表现用
    export function entAlertPage(auths,pageNo,pageSize,companyId) {
      return requests({
        url: '/api/subsystem/bigs/entAlertPage?auths='+auths+'&pageNo='+pageNo+'&pageSize='+pageSize+'&companyId='+companyId,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
    //  预警列表
    export function alertList(auths,companyId) {
      return requests({
        url: '/api/subsystem/bigs/alertList?auths='+auths+'&companyId='+companyId,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
     //  非控制设备列表
     export function entNonControlDevice(auths,companyId) {
      return requests({
        url: '/api/subsystem/bigs/entNonControlDevice?auths='+auths+'&companyId='+companyId,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
    //  摄像头列表
    export function camSurvPage(auths,pageNo,pageSize,companyId) {
      return requests({
        url: '/api/subsystem/bigs/camSurvPage?auths='+auths+'&pageNo='+pageNo+'&pageSize='+pageSize+'&companyId='+companyId,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }

    //  查询单个设备监测数据
    export function getDeviceTransData(auths,deployId,pageNo,pageSize) {
      return requests({
        url: '/api/subsystem/bigs/getDeviceTransData?auths='+auths+'&deployId='+deployId+'&pageNo='+pageNo+'&pageSize='+pageSize,
        method: 'get',
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
    //  摄像头控制开始
    export function cameraStart(obj) {
      return requests({
        url: '/api/subsystem/bigs/cameraStart',
        method: 'post',
        data:obj,
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }
    //  摄像头控制结束
    export function cameraStop(val) {
      return requests({
        url: '/api/subsystem/bigs/cameraStop',
        method: 'post',
        data:val,
        headers: {
          // 'X-Access-Token':window.sessionStorage.getItem("token")
        },
      })
    }