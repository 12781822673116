import axios from "axios";

const requests = axios.create({
    baseURL:"/jeecg-boot",
    timeout:20000,
});

// 设置公共的 Header
requests.interceptors.request.use(config => {
    // 从 localStorage, Vuex, cookies 等获取 token
    const token = localStorage.getItem('token'); // 假设你的 token 存储在 localStorage 中
    if (token) {
      config.headers['X-Access-Token'] = `${token}`; // 根据你的需求设置 Header
    }
    return config;
  }, error => {
    // 请求错误处理
    Promise.reject(error);
  });


// 请求拦截器
requests.interceptors.request.use((config)=>{
    return config;
})

// 响应拦截器
requests.interceptors.response.use((res)=>{
    return res.data;
},(error)=>{
    return Promise.reject(new Error('faile'));
});

export default requests;